<template>
  <div role="main" class="main">
    <section
      class="page-header page-header-modern bg-color-grey page-header-md"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col">
            <RouterLink
              class="portfolio-prev text-decoration-none d-block appear-animation"
              data-appear-animation="fadeInRightShorter"
              to="bigfair"
              ><div class="d-flex align-items-center line-height-1">
                <i class="fas fa-arrow-left text-dark text-4 me-3"></i>
                <div class="d-none d-sm-block line-height-1">
                  <span class="text-dark opacity-4 text-1"
                    >PREVIOUS PROJECT</span
                  >
                  <h4 class="font-weight-bold text-3 mb-0">Big Fair</h4>
                </div>
              </div></RouterLink
            >
          </div>

          <div class="col">
            <div class="row">
              <div
                class="col-md-12 align-self-center p-static order-2 text-center"
              >
                <div class="overflow-hidden pb-2">
                  <h1
                    class="text-dark font-weight-bold text-9 appear-animation"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay="100"
                  >
                    Mentorsip
                  </h1>
                </div>
              </div>
              <div class="col-md-12 align-self-center order-1">
                <ul
                  class="breadcrumb d-block text-center appear-animation"
                  data-appear-animation="fadeIn"
                  data-appear-animation-delay="300"
                >
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Projects</a></li>
                  <li class="active">Mentorsip</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <RouterLink
              class="portfolio-next text-decoration-none d-block float-end appear-animation"
              data-appear-animation="fadeInLeftShorter"
              to="toiskin"
              ><div class="d-flex align-items-center text-end line-height-1">
                <div class="d-none d-sm-block line-height-1">
                  <span class="text-dark opacity-4 text-1">NEXT PROJECT</span>
                  <h4 class="font-weight-bold text-3 mb-0">Toiskin Care</h4>
                </div>
                <i class="fas fa-arrow-right text-dark text-4 ms-3"></i></div
            ></RouterLink>
          </div>
        </div>
      </div>
    </section>

    <div class="container py-4">
      <div class="row">
        <div
          class="col appear-animation"
          data-appear-animation="fadeInUpShorter"
          data-appear-animation-delay="300"
        >
          <div
            class="owl-carousel owl-theme nav-inside nav-inside-edge nav-squared nav-with-transparency nav-dark nav-lg d-block overflow-hidden"
            data-plugin-options="{'items': 1, 'margin': 10, 'loop': false, 'nav': true, 'dots': false, 'autoHeight': true}"
            style="height: 510px"
          >
            <div>
              <div class="img-thumbnail border-0 border-radius-0 p-0 d-block">
                <img
                  src="/img/products/mentorsip_1.jpg"
                  class="img-fluid border-radius-0"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-4 mt-2 mb-5">
        <div class="col-md-7 mb-4 mb-md-0">
          <h2 class="text-color-dark font-weight-normal text-5 mb-2">
            Project <strong class="font-weight-extra-bold">Description</strong>
          </h2>
          <p>
            Social media interaktif antara Mentor dengan para fans dan
            pengikutnya.
          </p>
          <p>
            E-Learning media belajar menggunakan aplikasi mobile Android dan
            iOS.
          </p>
        </div>
        <div class="col-md-5">
          <h2 class="text-color-dark font-weight-normal text-5 mb-2">
            Project <strong class="font-weight-extra-bold">Details</strong>
          </h2>
          <ul class="list list-icons list-primary list-borders text-2">
            <li>
              <i class="fas fa-caret-right left-10"></i>
              <strong class="text-color-primary">Date : </strong> January 2021
            </li>
            <li>
              <i class="fas fa-caret-right left-10"></i>
              <strong class="text-color-primary">Platforms : </strong>
              <a
                href="#"
                class="badge badge-dark badge-sm rounded-pill px-2 py-1 ms-1"
                >iOS</a
              ><a
                href="#"
                class="badge badge-dark badge-sm rounded-pill px-2 py-1 ms-1"
                >Android</a
              ><a
                href="#"
                class="badge badge-dark badge-sm rounded-pill px-2 py-1 ms-1"
                >Website</a
              >
            </li>
            <li>
              <i class="fas fa-caret-right left-10"></i>
              <strong class="text-color-primary">Instagram URL : </strong>
              <a
                href="https://www.instagram.com/mentorsip/"
                target="_blank"
                class="text-dark"
                >https://www.instagram.com/mentorsip/</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { createdJSFile, removeJSFile } from "@/assets/custom";

let lastRoute = "";
onMounted(() => {
  removeJSFile();
  const route = useRoute();
  lastRoute = route.path;
  createdJSFile(route.path);
});
</script>
