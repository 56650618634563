const createdJSFile = (route?: string): void => {
  const scripts = [
    "/vendor/plugins/js/plugins.min.js",
    "/js/theme.js",
    "/vendor/rs-plugin/revolution-addons/typewriter/js/revolution.addon.typewriter.min.js",
    "/js/theme.init.js",
  ];
  if (route != undefined && route == "/contact") scripts.push("/js/map.js");
  scripts.forEach((script) => {
    let tag = document.createElement("script");
    tag.setAttribute("src", script);
    document.body.appendChild(tag);
  });
};

const removeJSFile = (): void => {
  const scripts = [
    "/vendor/plugins/js/plugins.min.js",
    "/js/theme.js",
    "/vendor/rs-plugin/revolution-addons/typewriter/js/revolution.addon.typewriter.min.js",
    "/js/map.js",
    "/js/theme.init.js",
  ];
  scripts.forEach((script) => {
    const tag = document.querySelector(`script[src="${script}"]`);
    if (tag != undefined) document.body.removeChild(tag);
  });

  const maps = document.head.querySelectorAll("script");
  maps.forEach((map) => {
    document.head.removeChild(map);
  });
};

export { createdJSFile, removeJSFile };
