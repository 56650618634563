<template>
  <div role="main" class="main">
    <section
      class="page-header page-header-modern bg-color-grey page-header-md"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div
                class="col-md-12 align-self-center p-static order-2 text-center"
              >
                <div class="overflow-hidden pb-2">
                  <h1
                    class="text-dark font-weight-bold text-9 appear-animation"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay="100"
                  >
                    Portfolios
                  </h1>
                </div>
              </div>
              <div class="col-md-12 align-self-center order-1">
                <ul
                  class="breadcrumb d-block text-center appear-animation"
                  data-appear-animation="fadeIn"
                  data-appear-animation-delay="300"
                >
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Portfolio</a></li>
                  <li class="active">Software Development</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container py-2">
      <ul
        id="portfolioPaginationFilter"
        class="nav nav-pills sort-source sort-source-style-3 justify-content-center"
        data-sort-id="portfolio"
        data-option-key="filter"
        data-plugin-options="{'layoutMode': 'fitRows'}"
      >
        <li class="nav-item active" data-option-value="*">
          <a class="nav-link text-2-5 text-uppercase active" href="#"
            >Show All</a
          >
        </li>
        <li class="nav-item" data-option-value=".websites">
          <a class="nav-link text-2-5 text-uppercase" href="#">Websites</a>
        </li>
        <li class="nav-item" data-option-value=".mobile_app">
          <a class="nav-link text-2-5 text-uppercase" href="#">Mobile App</a>
        </li>
        <li class="nav-item" data-option-value=".enterprise">
          <a class="nav-link text-2-5 text-uppercase" href="#">Enterprise</a>
        </li>
      </ul>

      <div
        class="sort-destination-loader sort-destination-loader-showing mt-4 pt-2"
      >
        <div
          id="portfolioPaginationWrapper"
          class="row portfolio-list sort-destination"
          data-sort-id="portfolio"
          data-items-per-page="8"
        >
          <div class="col-sm-6 col-lg-3 isotope-item websites">
            <div class="portfolio-item">
              <RouterLink class="dropdown-item dropdown-toggle" to="/project">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img
                      src="/img/portfolios/toiskin.webp"
                      class="img-fluid border-radius-0"
                      alt=""
                    />
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">Toiskin</span>
                      <span class="thumb-info-type">Website</span>
                    </span>
                    <span class="thumb-info-action">
                      <span class="thumb-info-action-icon bg-dark opacity-8"
                        ><i class="fas fa-plus"></i
                      ></span>
                    </span>
                  </span> </span
              ></RouterLink>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 isotope-item websites">
            <div class="portfolio-item">
              <RouterLink class="dropdown-item dropdown-toggle" to="/project">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img
                      src="/img/portfolios/cas.webp"
                      class="img-fluid border-radius-0"
                      alt=""
                    />
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner"
                        >Cipta Abirama Sinergi</span
                      >
                      <span class="thumb-info-type">Website</span>
                    </span>
                    <span class="thumb-info-action">
                      <span class="thumb-info-action-icon bg-dark opacity-8"
                        ><i class="fas fa-plus"></i
                      ></span>
                    </span>
                  </span> </span
              ></RouterLink>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 isotope-item websites">
            <div class="portfolio-item">
              <RouterLink class="dropdown-item dropdown-toggle" to="/project">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img
                      src="/img/portfolios/ahtemas.webp"
                      class="img-fluid border-radius-0"
                      alt=""
                    />
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">Ahtemas</span>
                      <span class="thumb-info-type">Website</span>
                    </span>
                    <span class="thumb-info-action">
                      <span class="thumb-info-action-icon bg-dark opacity-8"
                        ><i class="fas fa-plus"></i
                      ></span>
                    </span>
                  </span> </span
              ></RouterLink>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 isotope-item websites">
            <div class="portfolio-item">
              <RouterLink class="dropdown-item dropdown-toggle" to="/project">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img
                      src="/img/portfolios/ptmks.webp"
                      class="img-fluid border-radius-0"
                      alt=""
                    />
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">PT MKS</span>
                      <span class="thumb-info-type">Website</span>
                    </span>
                    <span class="thumb-info-action">
                      <span class="thumb-info-action-icon bg-dark opacity-8"
                        ><i class="fas fa-plus"></i
                      ></span>
                    </span>
                  </span> </span
              ></RouterLink>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 isotope-item logos">
            <div class="portfolio-item">
              <RouterLink class="dropdown-item dropdown-toggle" to="/project">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img
                      src="/img/portfolios/jpkp.webp"
                      class="img-fluid border-radius-0"
                      alt=""
                    />
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">JPKP Koperasi</span>
                      <span class="thumb-info-type">Website</span>
                    </span>
                    <span class="thumb-info-action">
                      <span class="thumb-info-action-icon bg-dark opacity-8"
                        ><i class="fas fa-plus"></i
                      ></span>
                    </span>
                  </span> </span
              ></RouterLink>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 isotope-item enterprise">
            <div class="portfolio-item">
              <RouterLink class="dropdown-item dropdown-toggle" to="/project">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img
                      src="/img/portfolios/bblk.webp"
                      class="img-fluid border-radius-0"
                      alt=""
                    />
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner"
                        >Halo Lab BBLK Palembang</span
                      >
                      <span class="thumb-info-type">Enterprise</span>
                    </span>
                    <span class="thumb-info-action">
                      <span class="thumb-info-action-icon bg-dark opacity-8"
                        ><i class="fas fa-plus"></i
                      ></span>
                    </span>
                  </span> </span
              ></RouterLink>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 isotope-item enterprise">
            <div class="portfolio-item">
              <RouterLink class="dropdown-item dropdown-toggle" to="/project">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img
                      src="/img/portfolios/hcm.webp"
                      class="img-fluid border-radius-0"
                      alt=""
                    />
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner"
                        >HCM Petrokimia Gresik</span
                      >
                      <span class="thumb-info-type">Enterprise</span>
                    </span>
                    <span class="thumb-info-action">
                      <span class="thumb-info-action-icon bg-dark opacity-8"
                        ><i class="fas fa-plus"></i
                      ></span>
                    </span>
                  </span> </span
              ></RouterLink>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 isotope-item mobile_app">
            <div class="portfolio-item">
              <RouterLink class="dropdown-item dropdown-toggle" to="/project">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img
                      src="/img/portfolios/emuslim.webp"
                      class="img-fluid border-radius-0"
                      alt=""
                    />
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">Emuslim</span>
                      <span class="thumb-info-type">Mobile App</span>
                    </span>
                    <span class="thumb-info-action">
                      <span class="thumb-info-action-icon bg-dark opacity-8"
                        ><i class="fas fa-plus"></i
                      ></span>
                    </span>
                  </span> </span
              ></RouterLink>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 isotope-item mobile_app">
            <div class="portfolio-item">
              <RouterLink class="dropdown-item dropdown-toggle" to="/project">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img
                      src="/img/portfolios/gklu.webp"
                      class="img-fluid border-radius-0"
                      alt=""
                    />
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">Golden KL Union</span>
                      <span class="thumb-info-type">Mobile App</span>
                    </span>
                    <span class="thumb-info-action">
                      <span class="thumb-info-action-icon bg-dark opacity-8"
                        ><i class="fas fa-plus"></i
                      ></span>
                    </span>
                  </span> </span
              ></RouterLink>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 isotope-item mobile_app">
            <div class="portfolio-item">
              <RouterLink class="dropdown-item dropdown-toggle" to="/project">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img
                      src="/img/portfolios/haqq.webp"
                      class="img-fluid border-radius-0"
                      alt=""
                    />
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">Haqq</span>
                      <span class="thumb-info-type">Mobile App</span>
                    </span>
                    <span class="thumb-info-action">
                      <span class="thumb-info-action-icon bg-dark opacity-8"
                        ><i class="fas fa-plus"></i
                      ></span>
                    </span>
                  </span> </span
              ></RouterLink>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 isotope-item enterprise">
            <div class="portfolio-item">
              <RouterLink class="dropdown-item dropdown-toggle" to="/project">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img
                      src="/img/portfolios/korlantas.webp"
                      class="img-fluid border-radius-0"
                      alt=""
                    />
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">Korlantas</span>
                      <span class="thumb-info-type">Enterprise</span>
                    </span>
                    <span class="thumb-info-action">
                      <span class="thumb-info-action-icon bg-dark opacity-8"
                        ><i class="fas fa-plus"></i
                      ></span>
                    </span>
                  </span> </span
              ></RouterLink>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div id="portfolioPagination" class="float-end"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { createdJSFile, removeJSFile } from "@/assets/custom";

let lastRoute = "";
onMounted(() => {
  removeJSFile();
  const route = useRoute();
  lastRoute = route.path;
  createdJSFile(route.path);
});
</script>
