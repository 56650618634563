<template>
  <div role="main" class="main">
    <section
      class="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7"
      style="background-image: url(img/slides/background_about_1.jpg)"
    >
      <div class="container">
        <div class="row mt-5">
          <div class="col-md-12 align-self-center p-static order-2 text-center">
            <h1 class="text-9 font-weight-bold">About Us</h1>
          </div>
          <div class="col-md-12 align-self-center order-1">
            <ul class="breadcrumb breadcrumb-light d-block text-center">
              <li><a href="#">Home</a></li>
              <li class="active">Pages</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="row pt-5">
        <div class="col">
          <div class="row text-center pb-5">
            <div class="col-md-9 mx-md-auto">
              <div class="overflow-hidden mb-3">
                <h1
                  class="word-rotator slide font-weight-bold text-8 mb-0 appear-animation"
                  data-appear-animation="maskUp"
                >
                  <span>We are Olpedia, We </span>
                  &nbsp;
                  <span class="word-rotator-words bg-primary">
                    <b class="is-visible">Create</b>
                    <b>Build</b>
                    <b>Develop</b>
                  </span>
                  &nbsp;
                  <span> Solutions</span>
                </h1>
              </div>
              <div class="overflow-hidden mb-3">
                <p
                  class="lead mb-0 appear-animation"
                  data-appear-animation="maskUp"
                  data-appear-animation-delay="200"
                ></p>
                <p>
                  PT. Olpedia Global Teknologi adalah sebuah perseroan yang
                  didirikan di Indonesia tanggal 16 Oktober 2017 yang bergerak
                  dibidang teknologi, event organizer, dan perdagangan umum
                  lainnya. Perjalanan Olpedia dimulai dengan mengerjakan proyek
                  teknologi pada puluhan perusahaan dibidang keuangan baik di
                  dalam maupun diluar negeri.
                </p>
                <p>
                  Olpedia mengembangkan sayap dibidang event organizer yang
                  telah berhasil mengadakan enterpreneurship event di lebih dari
                  7 kota dengan total peserta lebih dari 50.000 peserta dengan
                  ratusan pembicaraan ternama. Olpedia kemudian menciptakan
                  sebuah aplikasi edu-tech yang menghubungkan para mentor
                  ternama dengan pengikutnya secara online untuk pembelajaran
                  dan konsultasi privat melalui sebuah aplikasi yang dinamakan
                  Mentorsip. Berbekal Perusahaan yang terdiri dari para
                  professional muda, kreatif dan berbakat.
                </p>
                <p>
                  Olpedia akan mengembangkan lagi perusahaannya dengan
                  menciptakan produk produk unggulan yang harapannya bisa
                  bermanfaat untuk kepentingan bangsa dan negara.
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-3 mb-5">
            <div
              class="col-md-4 appear-animation"
              data-appear-animation="fadeInLeftShorter"
              data-appear-animation-delay="800"
            >
              <h3 class="font-weight-bold text-4 mb-2">Our Mission</h3>
              <p>Memberikan edukasi dan support kepada masyarakat.</p>
            </div>
            <div
              class="col-md-4 appear-animation"
              data-appear-animation="fadeIn"
              data-appear-animation-delay="600"
            >
              <h3 class="font-weight-bold text-4 mb-2">Our Vision</h3>
              <p>Membantu menjadikan masyarakat dengan financial freedom.</p>
            </div>
            <div
              class="col-md-4 appear-animation"
              data-appear-animation="fadeInRightShorter"
              data-appear-animation-delay="800"
            >
              <h3 class="font-weight-bold text-4 mb-2">Why Us</h3>
              <p>
                Kami berpengalaman dalam membangun bisnis dan membantu
                masyarakat dalam mencapai cita-citanya.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container appear-animation"
      data-appear-animation="fadeInUpShorter"
      data-appear-animation-delay="300"
    >
      <div class="row pt-5 pb-4 my-5">
        <div class="col-md-6 order-2 order-md-1 text-center text-md-start">
          <div
            class="owl-carousel owl-theme nav-style-1 nav-center-images-only stage-margin mb-0"
            data-plugin-options="{'responsive': {'576': {'items': 1}, '768': {'items': 1}, '992': {'items': 2}, '1200': {'items': 2}}, 'margin': 25, 'loop': false, 'nav': true, 'dots': false, 'stagePadding': 40}"
          >
            <div>
              <img
                class="img-fluid rounded-0 mb-4"
                src="/img/team/founder.jpg"
                alt=""
              />
              <h3 class="font-weight-bold text-color-dark text-4 mb-0">
                Abi Atria Binangun
              </h3>
              <p class="text-2 mb-0">Founder Olpedia</p>
            </div>
            <div>
              <img
                class="img-fluid rounded-0 mb-4"
                src="/img/team/ceo.jpg"
                alt=""
              />
              <h3 class="font-weight-bold text-color-dark text-4 mb-0">
                Bilyantoro
              </h3>
              <p class="text-2 mb-0">Chief Operating Officer</p>
            </div>
            <div>
              <img
                class="img-fluid rounded-0 mb-4"
                src="/img/team/cto.jpg"
                alt=""
              />
              <h3 class="font-weight-bold text-color-dark text-4 mb-0">
                Gigih Iski Prasetyawan
              </h3>
              <p class="text-2 mb-0">Chief Technology Officer</p>
            </div>
            <div>
              <img
                class="img-fluid rounded-0 mb-4"
                src="/img/team/gm.jpg"
                alt=""
              />
              <h3 class="font-weight-bold text-color-dark text-4 mb-0">
                Amalia Yusrina
              </h3>
              <p class="text-2 mb-0">General Manager</p>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 order-1 order-md-2 text-center text-md-start mb-5 mb-md-0"
        >
          <h2 class="text-color-dark font-weight-normal text-6 mb-2 pb-1">
            Meet <strong class="font-weight-extra-bold">Our Board</strong>
          </h2>
          <p class="mb-4">
            Para board Kami telah memiliki pengalaman yang cukup banyak
            dibidangnya, dan berkontribusi penuh dalam membangun perusahaan ini.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { createdJSFile, removeJSFile } from "@/assets/custom";

let lastRoute = "";
onMounted(() => {
  removeJSFile();
  const route = useRoute();
  lastRoute = route.path;
  createdJSFile(route.path);
});
</script>
