<template>
  <footer id="footer" class="mt-0">
    <div class="container container-xl-custom my-4">
      <div class="row py-5">
        <div class="col-lg-4 mb-5 mb-lg-0 text-center text-lg-start pt-3">
          <h5
            class="text-5 text-transform-none font-weight-semibold text-color-light mb-4"
          >
            PT. Olpedia Global Teknologi
          </h5>
          <p class="text-4 mb-3">Olpedia Global Teknologi</p>
        </div>
        <div class="col-lg-2 mb-4 mb-md-0 text-center text-lg-start pt-3">
          <h5
            class="text-5 text-transform-none font-weight-semibold text-color-light mb-4"
          >
            Pages
          </h5>
          <ul class="list list-icons list-icons-sm d-inline-flex flex-column">
            <li class="text-4 mb-2">
              <i class="fas fa-angle-right"></i>
              <RouterLink class="link-hover-style-1 ms-1" to="services"
                >Our Services</RouterLink
              >
            </li>
            <li class="text-4 mb-2">
              <i class="fas fa-angle-right"></i>

              <RouterLink class="link-hover-style-1 ms-1" to="whoweare"
                >About Us</RouterLink
              >
            </li>
            <li class="text-4 mb-2">
              <i class="fas fa-angle-right"></i>

              <RouterLink class="link-hover-style-1 ms-1" to="contact"
                >Contact Us</RouterLink
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-3 mb-4 mb-lg-0 text-center text-lg-start pt-3">
          <h5
            class="text-5 text-transform-none font-weight-semibold text-color-light mb-4"
          >
            Contact Us
          </h5>
          <p class="text-4 mb-2">
            <span class="text-color-light">Address : </span>Jl. Dukuh Balas
            No.223, Balas Klumprik, Wiyung<br />
          </p>
          <p class="text-4 mb-2">Surabaya, East Java 60222</p>
        </div>
        <div class="col-lg-3 text-center text-lg-start pt-3">
          <h5
            class="text-5 text-transform-none font-weight-semibold text-color-light mb-4"
          >
            Follow Us
          </h5>
          <ul
            class="footer-social-icons social-icons social-icons-clean social-icons-big social-icons-opacity-light social-icons-icon-light mt-0 mt-lg-3"
          >
            <li class="social-icons-instagram">
              <a
                href="https://instagram.com/olpediaid"
                target="_blank"
                title="Instagram"
                ><i class="fab fa-instagram text-2"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container container-xl-custom">
      <div class="footer-copyright footer-copyright-style-2">
        <div class="py-2">
          <div class="row py-4">
            <div
              class="col d-flex align-items-center justify-content-center mb-4 mb-lg-0"
            >
              <p>© Copyright 2024. PT. Olpedia Global Teknologi.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
