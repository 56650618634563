<template>
  <div role="main" class="main">
    <section
      class="section overlay overlay-show overlay-op-8 border-0 m-0 p-0"
      style="
        background-image: url(/img/slides/background_home_1.jpg);
        background-size: cover;
        background-position: center;
        height: 100vh;
      "
    >
      <div class="container container-xl-custom pt-5 h-100">
        <div class="row align-items-center pt-5 h-100">
          <div class="col">
            <h1
              class="word-rotator letters type type-clean-light text-color-light font-weight-extra-bold text-12 line-height-2 mb-3 appear-animation"
              data-appear-animation="fadeIn"
              data-appear-animation-delay="300"
            >
              <span>We are the Best</span><br />
              <span class="word-rotator-words waiting">
                <b class="is-visible">and we are Brave.</b>
                <b>and we are Intelligent.</b>
                <b>and we are Growth.</b>
                <b>because we are Awesome!</b>
              </span>
            </h1>
            <p
              class="text-4-5 text-color-light font-weight-light opacity-9 mb-4"
              data-plugin-animated-letters
              data-plugin-options="{'startDelay': 1500, 'minWindowWidth': 0, 'animationSpeed': 50}"
            >
              Olpedia Global Teknologi
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="container container-xl-custom py-5 my-5">
      <div class="row justify-content-center">
        <div class="col-xl-9 text-center">
          <h2
            class="font-weight-bold text-11 appear-animation"
            data-appear-animation="fadeInUpShorter"
          >
            We are Olpedia Team
          </h2>
          <p
            class="line-height-9 text-4 opacity-9 appear-animation"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="200"
          >
            <p>PT. Olpedia Global Teknologi adalah sebuah perseroan yang didirikan
            di Indonesia tanggal 16 Oktober 2017 yang bergerak dibidang
            teknologi, event organizer, dan perdagangan umum lainnya. Perjalanan
            Olpedia dimulai dengan mengerjakan proyek teknologi pada puluhan
            perusahaan dibidang keuangan baik di dalam maupun diluar negeri.</p>
            <p>Olpedia mengembangkan sayap dibidang event organizer yang telah
            berhasil mengadakan enterpreneurship event di lebih dari 7 kota
            dengan total peserta lebih dari 50.000 peserta dengan ratusan
            pembicaraan ternama. Olpedia kemudian menciptakan sebuah aplikasi
            edu-tech yang menghubungkan para mentor ternama dengan pengikutnya
            secara online untuk pembelajaran dan konsultasi privat melalui
            sebuah aplikasi yang dinamakan Mentorsip. Berbekal Perusahaan yang
            terdiri dari para professional muda, kreatif dan berbakat.</p>
            <p>Olpedia
            akan mengembangkan lagi perusahaannya dengan menciptakan produk
            produk unggulan yang harapannya bisa bermanfaat untuk kepentingan
            bangsa dan negara.</p>
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { createdJSFile, removeJSFile } from "@/assets/custom";

let lastRoute = "";
onMounted(() => {
  removeJSFile();
  const route = useRoute();
  lastRoute = route.path;
  createdJSFile(route.path);
});
</script>