<template>
  <div role="main" class="main">
    <section
      class="page-header page-header-modern bg-color-grey page-header-md"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col">
            <RouterLink
              class="portfolio-prev text-decoration-none d-block appear-animation"
              data-appear-animation="fadeInRightShorter"
              to="yefestival"
              ><div class="d-flex align-items-center line-height-1">
                <i class="fas fa-arrow-left text-dark text-4 me-3"></i>
                <div class="d-none d-sm-block line-height-1">
                  <span class="text-dark opacity-4 text-1"
                    >PREVIOUS PROJECT</span
                  >
                  <h4 class="font-weight-bold text-3 mb-0">
                    Young Entrepreneur Festival
                  </h4>
                </div>
              </div></RouterLink
            >
          </div>

          <div class="col">
            <div class="row">
              <div
                class="col-md-12 align-self-center p-static order-2 text-center"
              >
                <div class="overflow-hidden pb-2">
                  <h1
                    class="text-dark font-weight-bold text-9 appear-animation"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay="100"
                  >
                    emuslim
                  </h1>
                </div>
              </div>
              <div class="col-md-12 align-self-center order-1">
                <ul
                  class="breadcrumb d-block text-center appear-animation"
                  data-appear-animation="fadeIn"
                  data-appear-animation-delay="300"
                >
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Projects</a></li>
                  <li class="active">emuslim</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <RouterLink
              class="portfolio-next text-decoration-none d-block float-end appear-animation"
              data-appear-animation="fadeInLeftShorter"
              to="bigfair"
              ><div class="d-flex align-items-center text-end line-height-1">
                <div class="d-none d-sm-block line-height-1">
                  <span class="text-dark opacity-4 text-1">NEXT PROJECT</span>
                  <h4 class="font-weight-bold text-3 mb-0">Big Fair</h4>
                </div>
                <i class="fas fa-arrow-right text-dark text-4 ms-3"></i></div
            ></RouterLink>
          </div>
        </div>
      </div>
    </section>

    <div class="container py-4">
      <div class="row">
        <div
          class="col appear-animation"
          data-appear-animation="fadeInUpShorter"
          data-appear-animation-delay="300"
        >
          <div
            class="owl-carousel owl-theme nav-inside nav-inside-edge nav-squared nav-with-transparency nav-dark nav-lg d-block overflow-hidden"
            data-plugin-options="{'items': 1, 'margin': 10, 'loop': false, 'nav': true, 'dots': false, 'autoHeight': true}"
            style="height: 510px"
          >
            <div>
              <div class="img-thumbnail border-0 border-radius-0 p-0 d-block">
                <img
                  src="/img/products/emuslim_1.jpg"
                  class="img-fluid border-radius-0"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-4 mt-2 mb-5">
        <div class="col-md-7 mb-4 mb-md-0">
          <h2 class="text-color-dark font-weight-normal text-5 mb-2">
            Project <strong class="font-weight-extra-bold">Description</strong>
          </h2>

          <p>
            Ensiklopedia Muslim adalah kamus yang memberikan petunjuk, ilmu
            pengetahuan dan tuntunan bagi umat Islam baik untuk melaksanakan
            ibadah yang berarti hubungan manusia dengan Tuhan (habluminallah)
            maupun melaksanakan muamalah yang berarti hubungan manusia dengan
            manusia lainnya (habluminannaas) dalam rangka mencapai derajat
            seorang muslim yang beriman dan bertaqwa kepada Allah SWT.
          </p>
        </div>

        <div class="col-md-5">
          <h2 class="text-color-dark font-weight-normal text-5 mb-2">
            Project <strong class="font-weight-extra-bold">Details</strong>
          </h2>
          <ul class="list list-icons list-primary list-borders text-2">
            <li>
              <i class="fas fa-caret-right left-10"></i>
              <strong class="text-color-primary">Date : </strong> Maret 2024
            </li>
            <li>
              <i class="fas fa-caret-right left-10"></i>
              <strong class="text-color-primary">Platforms : </strong>
              <a
                href="#"
                class="badge badge-dark badge-sm rounded-pill px-2 py-1 ms-1"
                >iOS</a
              ><a
                href="#"
                class="badge badge-dark badge-sm rounded-pill px-2 py-1 ms-1"
                >Android</a
              ><a
                href="#"
                class="badge badge-dark badge-sm rounded-pill px-2 py-1 ms-1"
                >Website</a
              >
            </li>
            <li>
              <i class="fas fa-caret-right left-10"></i>
              <strong class="text-color-primary">Project URL : </strong>
              <a href="https://emuslim.id" target="_blank" class="text-dark"
                >https://emuslim.id</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row pb-4 mt-2">
      <div
        class="col-lg-4 appear-animation"
        data-appear-animation="fadeInRightShorter"
      >
        <div class="feature-box feature-box-style-2">
          <div class="feature-box-icon">
            <i class="icons icon-star text-color-secondary"></i>
          </div>
          <div class="feature-box-info">
            <h4 class="font-weight-bold mb-2">Cari Kajian</h4>
            <p>
              Mesin pencari kajian yang paling dekat dengan atau berdasarkan
              asatidz.
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 appear-animation"
        data-appear-animation="fadeInRightShorter"
        data-appear-animation-delay="200"
      >
        <div class="feature-box feature-box-style-2">
          <div class="feature-box-icon">
            <i class="icons icon-star text-color-secondary"></i>
          </div>
          <div class="feature-box-info">
            <h4 class="font-weight-bold mb-2">Al Quran</h4>
            <p>
              Kitab suci umat Islam lengkap dengan fitur terjemahan, qari, dll.
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 appear-animation"
        data-appear-animation="fadeInRightShorter"
        data-appear-animation-delay="400"
      >
        <div class="feature-box feature-box-style-2">
          <div class="feature-box-icon">
            <i class="icons icon-star text-color-secondary"></i>
          </div>
          <div class="feature-box-info">
            <h4 class="font-weight-bold mb-2">Hadits</h4>
            <p>
              Kumpulan hadits dari 9 perawi hadits terkemuka yang dilengkapi
              dengan mesin pencari hadits berdasarkan kata kunci.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-4">
      <div
        class="col-lg-4 appear-animation"
        data-appear-animation="fadeInLeftShorter"
        data-appear-animation-delay="800"
      >
        <div class="feature-box feature-box-style-2">
          <div class="feature-box-icon">
            <i class="icons icon-star text-color-secondary"></i>
          </div>
          <div class="feature-box-info">
            <h4 class="font-weight-bold mb-2">Jadwal Sholat</h4>
            <p>Jadwal sholat lima waktu menyesuaikan dengan lokasi pengguna.</p>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 appear-animation"
        data-appear-animation="fadeInLeftShorter"
        data-appear-animation-delay="600"
      >
        <div class="feature-box feature-box-style-2">
          <div class="feature-box-icon">
            <i class="icons icon-star text-color-secondary"></i>
          </div>
          <div class="feature-box-info">
            <h4 class="font-weight-bold mb-2">Kiblat</h4>
            <p>Panduan arah kiblat di Mekkah untuk arah sholat.</p>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 appear-animation"
        data-appear-animation="fadeInLeftShorter"
        data-appear-animation-delay="400"
      >
        <div class="feature-box feature-box-style-2">
          <div class="feature-box-icon">
            <i class="icons icon-star text-color-secondary"></i>
          </div>
          <div class="feature-box-info">
            <h4 class="font-weight-bold mb-2">Pedoman Sholat</h4>
            <p>Panduan atau tata cara sholat berdasarkan 4 madzhab imam.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-lg-4 appear-animation"
        data-appear-animation="fadeInRightShorter"
        data-appear-animation-delay="800"
      >
        <div class="feature-box feature-box-style-2">
          <div class="feature-box-icon">
            <i class="icons icon-star text-color-secondary"></i>
          </div>
          <div class="feature-box-info">
            <h4 class="font-weight-bold mb-2">Daftar Buku</h4>
            <p>Pasar buku Islam.</p>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 appear-animation"
        data-appear-animation="fadeInRightShorter"
        data-appear-animation-delay="1000"
      >
        <div class="feature-box feature-box-style-2">
          <div class="feature-box-icon">
            <i class="icons icon-star text-color-secondary"></i>
          </div>
          <div class="feature-box-info">
            <h4 class="font-weight-bold mb-2">Mahfudzot</h4>
            <p>
              Kumpulan mutiara hikmah Islam yang bersumber dari Al-Quran, Al
              Hadits, dan peribahasa Arab.
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 appear-animation"
        data-appear-animation="fadeInRightShorter"
        data-appear-animation-delay="1200"
      >
        <div class="feature-box feature-box-style-2">
          <div class="feature-box-icon">
            <i class="icons icon-star text-color-secondary"></i>
          </div>
          <div class="feature-box-info">
            <h4 class="font-weight-bold mb-2">Ta'aruf</h4>
            <p>Perkenalan antara Ikhwan dan Akhwat secara syar'i.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { createdJSFile, removeJSFile } from "@/assets/custom";

let lastRoute = "";
onMounted(() => {
  removeJSFile();
  const route = useRoute();
  lastRoute = route.path;
  createdJSFile(route.path);
});
</script>
