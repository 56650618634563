<script setup lang="ts">
import { RouterView } from "vue-router";
import { onMounted, onUpdated } from "vue";

import StickyHeader from "./components/StickyHeader.vue";
import FooterMenu from "./components/FooterMenu.vue";
import { createdJSFile, removeJSFile } from "@/assets/custom";
import { useRoute } from "vue-router";

let lastRoute = "";
onMounted(() => {
  removeJSFile();
  const route = useRoute();
  if (lastRoute != route.path) {
    lastRoute = route.path;
    createdJSFile(route.path);
  }
});
onUpdated(() => {
  removeJSFile();
  const route = useRoute();
  if (lastRoute != route.path) {
    lastRoute = route.path;
    createdJSFile(route.path);
  }
});
</script>

<template>
  <StickyHeader />

  <RouterView :key="$route.path" />

  <FooterMenu />
</template>
