<template>
  <div role="main" class="main">
    <!-- Google Maps - Go to the bottom of the page to change settings and map location. -->
    <div id="googlemaps" class="google-map mt-0" style="height: 500px"></div>

    <div class="container">
      <div class="row py-4">
        <div class="col-lg-6">
          <h2 class="font-weight-bold text-8 mt-2 mb-0">Contact Us</h2>
          <p class="mb-4">
            Feel free to ask for details, don't save any questions!
          </p>

          <form
            class="contact-form"
            action="php/contact-form.php"
            method="POST"
          >
            <div class="contact-form-success alert alert-success d-none mt-4">
              <strong>Success!</strong> Your message has been sent to us.
            </div>

            <div class="contact-form-error alert alert-danger d-none mt-4">
              <strong>Error!</strong> There was an error sending your message.
              <span class="mail-error-message text-1 d-block"></span>
            </div>

            <div class="row">
              <div class="form-group col-lg-6">
                <label class="form-label mb-1 text-2">Full Name</label>
                <input
                  type="text"
                  value=""
                  data-msg-required="Please enter your name."
                  maxlength="100"
                  class="form-control text-3 h-auto py-2"
                  name="name"
                  required
                />
              </div>
              <div class="form-group col-lg-6">
                <label class="form-label mb-1 text-2">Email Address</label>
                <input
                  type="email"
                  value=""
                  data-msg-required="Please enter your email address."
                  data-msg-email="Please enter a valid email address."
                  maxlength="100"
                  class="form-control text-3 h-auto py-2"
                  name="email"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col">
                <label class="form-label mb-1 text-2">Subject</label>
                <input
                  type="text"
                  value=""
                  data-msg-required="Please enter the subject."
                  maxlength="100"
                  class="form-control text-3 h-auto py-2"
                  name="subject"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col">
                <label class="form-label mb-1 text-2">Message</label>
                <textarea
                  maxlength="5000"
                  data-msg-required="Please enter your message."
                  rows="8"
                  class="form-control text-3 h-auto py-2"
                  name="message"
                  required
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="form-group col">
                <input
                  type="submit"
                  value="Send Message"
                  class="btn btn-secondary btn-modern"
                  data-loading-text="Loading..."
                />
              </div>
            </div>
          </form>
        </div>
        <div class="col-lg-6">
          <div
            class="appear-animation"
            data-appear-animation="fadeIn"
            data-appear-animation-delay="800"
          >
            <h4 class="mt-2 mb-1">Our <strong>Surabaya Office</strong></h4>
            <ul class="list list-icons list-icons-style-2 mt-2">
              <li>
                <i class="fas fa-map-marker-alt top-6"></i>
                <strong class="text-dark">Address : </strong>
                Jl. Dukuh Balas No.223<br />
                <span>Balas Klumprik, Wiyung<br /></span>
                <span>Surabaya, Jawa Timur</span>
              </li>
            </ul>
          </div>
          <div
            class="appear-animation"
            data-appear-animation="fadeIn"
            data-appear-animation-delay="800"
          >
            <h4 class="mt-2 mb-1">Our <strong>Bandung Office</strong></h4>
            <ul class="list list-icons list-icons-style-2 mt-2">
              <li>
                <i class="fas fa-map-marker-alt top-6 color-secondary"></i>
                <strong class="text-dark">Address : </strong>
                Jl. Dipatiukur No. 01 <br />
                <span>Kel. Lebakgede, Kec. Coblong</span> <br />
                <span>Bandung, Jawa Barat</span>
              </li>
            </ul>
          </div>

          <div
            class="appear-animation"
            data-appear-animation="fadeIn"
            data-appear-animation-delay="950"
          >
            <h4 class="pt-5">Business <strong>Hours</strong></h4>
            <ul class="list list-icons list-dark mt-2">
              <li>
                <i class="far fa-clock top-6"></i> Monday-Friday - 9am to 5pm
              </li>
              <li>
                <i class="far fa-clock top-6"></i> Saturday-Sunday - Closed
              </li>
            </ul>
          </div>

          <h4 class="pt-5">Get in <strong>Touch</strong></h4>
          <p class="lead mb-0 text-4">
            Jika Anda tidak menemukan informasi yang Anda cari, silakan hubungi
            kami. Team Kami akan menghubungi Anda kembali.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { createdJSFile, removeJSFile } from "@/assets/custom";

let lastRoute = "";
onMounted(() => {
  removeJSFile();
  const route = useRoute();
  lastRoute = route.path;
  createdJSFile(route.path);
});
</script>
