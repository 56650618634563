<template>
  <div role="main" class="main">
    <section
      class="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7"
      style="
        background-image: url(/img/background_service.jpg);
        padding: 220px 0 !important;
        margin: 0 0 10px 0 !important;
        background-size: auto !important;
      "
    >
      <div class="container">
        <div class="row mt-5">
          <div class="col-md-12 align-self-center p-static order-2 text-center">
            <h1 class="text-10">Our <strong>Services</strong></h1>
            <span class="sub-title">Kami Ada untuk Anda</span>
          </div>
          <div class="col-md-12 align-self-center order-1">
            <ul class="breadcrumb breadcrumb-light d-block text-center">
              <li><a href="#">Home</a></li>
              <li class="active">Pages</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <div class="container-fluid">
      <div class="row align-items-center bg-color-grey">
        <div class="col-lg-6 p-0">
          <section
            class="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
            data-plugin-parallax
            data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}"
            data-image-src="/img/services/event_organizer.jpg"
            style="min-height: 315px"
          ></section>
        </div>
        <div class="col-lg-6 p-0">
          <section class="section section-no-border h-100 m-0">
            <div class="row m-0">
              <div class="col-half-section col-half-section-left">
                <div class="overflow-hidden">
                  <h4
                    class="mb-0 appear-animation"
                    data-appear-animation="maskUp"
                  >
                    <a
                      href="#"
                      class="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1"
                      >Event Organizer</a
                    >
                  </h4>
                </div>
                <div class="overflow-hidden mb-2">
                  <p
                    class="mb-0 lead text-4 appear-animation"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay="200"
                  >
                    Event Planner Berpengalaman.
                  </p>
                </div>
                <p
                  class="text-2 mb-0 appear-animation"
                  data-appear-animation="fadeInUpShorter"
                  data-appear-animation-delay="400"
                >
                  Team Kami telah berpengalaman dalam mengadakan event baik
                  skala kecil maupun besar. Kami hadir untuk memenuhi kebutuhan
                  kegiatan event Anda.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="row align-items-center bg-color-grey">
        <div class="col-lg-6 order-2 order-lg-1 p-0">
          <section class="section section-no-border h-100 m-0">
            <div class="row justify-content-end m-0">
              <div
                class="col-half-section col-half-section-right custom-text-align-right"
              >
                <div class="overflow-hidden">
                  <h4
                    class="mb-0 appear-animation"
                    data-appear-animation="maskUp"
                  >
                    <a
                      href="#"
                      class="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1"
                      >Software Development</a
                    >
                  </h4>
                </div>
                <div class="overflow-hidden mb-2">
                  <p
                    class="mb-0 lead text-4 appear-animation"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay="200"
                  >
                    Kembangkan Bisnis Anda dengan Teknologi Terkini.
                  </p>
                </div>
                <p
                  class="text-2 mb-0 appear-animation"
                  data-appear-animation="fadeInUpShorter"
                  data-appear-animation-delay="400"
                >
                  Developer Kami yang terampil dan ahli dalam bekerja dengan
                  beragam bahasa pemrograman dan kerangka kerja, memastikan
                  pengembangan solusi perangkat lunak yang terukur, efisien, dan
                  aman. Dari pengembangan aplikasi hingga desain arsitektur
                  dengan solusi teknologi termutakhir.
                </p>
              </div>
            </div>
          </section>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 p-0">
          <section
            class="parallax section section-parallax h-100 m-0"
            data-plugin-parallax
            data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}"
            data-image-src="/img/services/software_development.jpg"
            style="min-height: 315px"
          ></section>
        </div>
      </div>
      <div class="row align-items-center bg-color-grey">
        <div class="col-lg-6 p-0">
          <section
            class="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
            data-plugin-parallax
            data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}"
            data-image-src="/img/products/toiskin_4.jpg"
            style="min-height: 315px"
          ></section>
        </div>
        <div class="col-lg-6 p-0">
          <section class="section section-no-border h-100 m-0">
            <div class="row m-0">
              <div class="col-half-section col-half-section-left">
                <div class="overflow-hidden">
                  <h4
                    class="mb-0 appear-animation"
                    data-appear-animation="maskUp"
                  >
                    <a
                      href="#"
                      class="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1"
                      >Skin Care</a
                    >
                  </h4>
                </div>
                <div class="overflow-hidden mb-2">
                  <p
                    class="mb-0 lead text-4 appear-animation"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay="200"
                  >
                    Lindungi Kulit Anda dan Jadilah Pengusaha.
                  </p>
                </div>
                <p
                  class="text-2 mb-0 appear-animation"
                  data-appear-animation="fadeInUpShorter"
                  data-appear-animation-delay="400"
                >
                  Produk Skin Care Kami telah teruji klinis dalam melindung dan
                  mempercantik kulit pria maupun wanita. Jadilah bagian dari
                  bisnis skin care Kami dan kembangkan usaha Anda dibidang skin
                  care .
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { createdJSFile, removeJSFile } from "@/assets/custom";

let lastRoute = "";
onMounted(() => {
  removeJSFile();
  const route = useRoute();
  lastRoute = route.path;
  createdJSFile(route.path);
});
</script>
