import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import YeFestivalView from "@/views/YeFestivalView.vue";
import ServiceView from "@/views/ServiceView.vue";
import WhoWeAreView from "@/views/WhoWeAreView.vue";
import AboutMeView from "@/views/AboutMeView.vue";
import ContactView from "@/views/ContactView.vue";
import EmuslimView from "@/views/EmuslimView.vue";
import MentorsipView from "@/views/MentorsipView.vue";
import ToiskinView from "@/views/ToiskinView.vue";
import BigfairView from "@/views/BigfairView.vue";
import SoftwareDevelopmentPortfolioView from "@/views/SoftwareDevelopmentPortfolioView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/services",
      name: "services",
      component: ServiceView,
    },
    {
      path: "/yefestival",
      name: "yefestival",
      component: YeFestivalView,
    },
    {
      path: "/emuslim",
      name: "emuslim",
      component: EmuslimView,
    },
    {
      path: "/bigfair",
      name: "bigfair",
      component: BigfairView,
    },
    {
      path: "/mentorsip",
      name: "mentorsip",
      component: MentorsipView,
    },
    {
      path: "/toiskin",
      name: "toiskin",
      component: ToiskinView,
    },
    {
      path: "/whoweare",
      name: "whoweare",
      component: WhoWeAreView,
    },
    {
      path: "/software_portfolio",
      name: "software_portfolio",
      component: SoftwareDevelopmentPortfolioView,
    },
    {
      path: "/aboutme",
      name: "aboutme",
      component: AboutMeView,
    },
    {
      path: "/contact",
      name: "contact",
      component: ContactView,
    },
  ],
});

export default router;
